import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { send } from "emailjs-com";
import ReactHtmlParser from 'react-html-parser';

import Footer from "./Footer";
import BreadCrumb from "./breadCrumb";

import "../styles/Projects.css";

function Contact() {
    const [toSend, setToSend] = useState({
        firstName: '',
        lastName: '',
        message: '',
        email: '',
        tel: ''
    });
    const [isSended, setIsSended] = useState(undefined);
    const [footer, setFooter] = useState(undefined);

    useEffect(() => {
        async function fetchFooter(){
            let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/footer";
            fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                setFooter(res[0].acf)
            })
        }
        fetchFooter();
        
    }, []);

    let message = "";

    const content = {
        from_name: toSend.firstName + ' ' + toSend.lastName,
        // reply_to: toSend.email,
        reply_to: "contact@groupe-fic.fr",
        tel: toSend.tel,
        email: toSend.email,
        message: toSend.message
    }

    const onSubmit = (e) => {
        e.preventDefault();
        send(
            'service_rj3dwio',
            'template_82d909j',
            content,
            'user_ARN1mBlL1uKfbh0LRi7g4'
          )
        .then((response) => {
            setIsSended("sended");
            setToSend({firstName: "", lastName: "", message: "", email: "", tel: ""});
            console.log('SUCCESS!', response.status, response.text);
        })
        .catch((err) => {
            setIsSended("notSended");
            console.log('FAILED...', err);
        });
    };

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    if(footer === undefined){
        return ""
    }

    if(isSended === "sended"){
        message = "Votre message a bien été envoyé. Nous vous contacterons dans les plus brefs délais.";
    }

    if(isSended === "notSended"){
        message = "Veuillez remplir tous les champs."
    }

    return (
    <div>
        <Helmet>
            <title>Contact - Groupe FIC</title>
            <meta name="description" content="Laissez-nous un message. Nous vous répondrons dans les plus brefs délais." />
            <link rel="canonical" href="https://groupe-fic.fr/contact" />
        </Helmet>
        <div className="content-block">
            <BreadCrumb page={`<strong>Contact</strong>`} />
        </div>

        <div className="block">
            <div className="top-contact content-block">
                <div className="block-50 resp-order-1">
                    <h1 className="font-size-40">Laissez-nous un message</h1>
                    {isSended !== undefined ? <div className={`message ${isSended}`}><p className={isSended}>{message}</p></div> : ""}
                    <form className="contact" onSubmit={onSubmit}>
                        <div className=" mosaic-block col-2 resp-gap-1-5">
                            <input 
                                name="lastName"
                                type="text" 
                                placeholder="Nom *" 
                                value={toSend.lastName}
                                onChange={handleChange}
                                required
                            />
                            <input 
                                name="firstName"
                                type="text" 
                                placeholder="Prénom *" 
                                value={toSend.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <input 
                            name="email"
                            type="text"
                            placeholder="Adresse email *" 
                            value={toSend.email}
                            onChange={handleChange}
                            required
                        />
                        <input 
                            name="tel"
                            type="number"
                            pattern="[0-9]{10}"
                            placeholder="Numéro téléphone *" 
                            value={toSend.tel}
                            onChange={handleChange}
                            required
                        />
                        <textarea
                            name="message"
                            placeholder="Votre message *" 
                            rows="15" 
                            value={toSend.message}
                            onChange={handleChange}
                            required
                        />
                        <div className="flex-row flex-start align-items-center gap-1">
                        <input type="checkbox" name="confidentialite" id="confidentialite" className="display-block min-width-0" required />
                        <label for="confidentialité">J'accepte la <Link to="/informations/politique-de-confidentialite">politique de confidentialité.</Link></label>
                        </div>
                        <input type="submit" value="Envoyer" />
                        
                    </form>
                </div>
                <div className="block-50 padding-0-32 resp-padding-0 margin-top-75">
                <div className="mosaic">
                        <div className="mosaic-block">
                            {ReactHtmlParser(footer.adresse_1)}
                        </div>
                        <div className="mosaic-block">
                            {ReactHtmlParser(footer.adresse_2)}
                        </div>
                        <div className="mosaic-block">
                            {ReactHtmlParser(footer.adresse_3)}
                        </div>
                        <div className="flex-align-center">
                            {footer.social.map(reseau => {
                                return(
                                    <a key={Math.random()} className="margin-right-20" href={reseau.link}><img className="margin-right-20" src={reseau.image.url} alt={reseau.image.description} /></a>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <Footer />
        </div>
    );
}

export default Contact;