import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import NotFound from "./NotFound";
import Header from "./Header";
import Footer from "./Footer";
import BreadCrumb from "./breadCrumb";

import "../styles/Group.css";
import Carousel from "./Carousel";

function _partners(content, partners) {
  return (
    <>
      <div className="content-block flex-column margin-bottom-50">
        <h2>{content.bloc_partners.title}</h2>
        {ReactHtmlParser(content.bloc_partners.text)}
        <section
          style={{
            height: " 100px",
            width: "100vw",
            margin: "0",
          }}
        >
          <div
            className="margin-top-15"
            style={{
              height: " 100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                gridGap: "2em",
                gap: "2em",
                overflowX: "hidden",
                /* align-items: center; */
                position: "relative",
              }}
            >
              <Carousel />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

function _garanties(content, garanties) {
  return (
    <div className="bg-f1f1f1 padding-50-0">
      <div className="content-block block flex-column">
        <h2>{content.bloc_agrements.title}</h2>
        <div className="flex-row resp-flex-wrap">
          <div className="block-50">
            {ReactHtmlParser(content.bloc_agrements.bloc_left.text)}
          </div>
          <div className="block-50">
            {ReactHtmlParser(content.bloc_agrements.bloc_right.texte)}
            <div className="mosaic align-items-center">
              {garanties.map((garantie) => {
                return (
                  <img
                    key={Math.random()}
                    src={garantie.image.url}
                    alt={garantie.image.description}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getScreenWidth() {
  const { innerWidth: width } = window;
  return width;
}

export default function Group(props) {
  const [content, setContent] = useState(undefined);
  const [partners, setPartners] = useState(undefined);
  const [garanties, setGaranties] = useState(undefined);
  const [imageIndex, setImageIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [header, setHeader] = useState(undefined);

  useEffect(() => {
    function fetchData() {
      let dataURL =
        "https://admin.groupe-fic.fr//wp-json/wp/v2/pages?slug=le-groupe";
      fetch(dataURL)
        .then((res) => res.json())
        .then((res) => {
          setContent(res[0].acf);
          setHeader(res[0].yoast_head_json);
        });
    }

    function fetchPartners() {
      let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/partenaires";
      fetch(dataURL)
        .then((res) => res.json())
        .then((res) => {
          const partenaires = res.filter(
            (content) => content.slug === "partenaires"
          );
          const garanties = res.filter(
            (content) => content.slug === "garanties"
          );
          setPartners(partenaires[0].acf.images);
          setGaranties(garanties[0].acf.images);
        });
    }

    function handleResize() {
      let windowWidth = getScreenWidth();
      if (windowWidth < 600) {
        setSlidesToShow(1);
      }
    }

    fetchData();
    fetchPartners();
    handleResize();
  }, []);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: slidesToShow,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  if (content === undefined) {
    return "";
  }

  if (content === null) {
    return <NotFound />;
  }

  return (
    <div>
      {header !== undefined ? (
        <Header content={header} match={props.match.url} />
      ) : (
        ""
      )}
      <div className="content-block">
        <BreadCrumb page={`<strong>${content.bloc_left.title1}</strong>`} />
      </div>
      <div id="le-groupe" className="block right-1e1e1e">
        <div className="content-block">
          <div className="block-50 padding-right-32">
            <h1 className="h1-64">{content.bloc_left.title1}</h1>
            <h2 className="h2-48">{content.bloc_left.title2}</h2>
            {ReactHtmlParser(content.bloc_left.text)}
          </div>
          <div className="block-50 flex-end padding-left-32">
            <img
              src={content.bloc_right.url}
              alt={content.bloc_right.description}
            />
          </div>
        </div>
      </div>

      <div
        id="entites"
        className="block block-center content-block margin-bottom-50 resp-margin-0"
      >
        <div className="mosaic gap-5 resp-gap-1">
          {content.fic_poles.mobile_content.poles.map((pole) => {
            return (
              <div key={Math.random()} className="mosaic-block width-45">
                <div className="width-100 border-bottom-1e1e1e margin-bottom-20">
                  <img
                    src={pole.image.url}
                    alt={pole.image.description}
                    width="75%"
                  />
                </div>
                {ReactHtmlParser(pole.texte)}
              </div>
            );
          })}
        </div>
      </div>

      <div className="block left-f1f1f1-75 padding-50-0 margin-bottom-75">
        <div className="content-block">
          <div className="block-50 flex-center flex-column padding-right-32">
            <h2 className="h2-64 margin-bottom-20">
              {content.bloc_fondateurs.bloc_left.title}
            </h2>
            {ReactHtmlParser(content.bloc_fondateurs.bloc_left.text)}
          </div>
          <div className="block-50 padding-left-32">
            <img
              src={content.bloc_fondateurs.bloc_right.url}
              alt={content.bloc_fondateurs.bloc_right.description}
            />
          </div>
        </div>
      </div>

      <section className="newsletter margin-bottom-50">
        <div className="content-block flex-column">
          <h3 className="font-size-36">{content.bloc_project.title}</h3>
          <Link to="/contact">
            <button>{content.bloc_project.button_text}</button>
          </Link>
        </div>
      </section>

      <div className="content-block flex-column">
        <h2 className="margin-bottom-50">{content.bloc_stats.title}</h2>
        <div className="mosaic margin-bottom-50 resp-center gap-10px">
          <div className="mosaic-bloc resp-stats">
            <p className="h1-61BF95 font-NotoSerif center">
              {content.bloc_stats.bloc_numbers.col1.number}
            </p>
            <h4 className="h4-stats margin-top-20 center">
              {content.bloc_stats.bloc_numbers.col1.text}
            </h4>
          </div>
          <div className="mosaic-bloc resp-stats">
            <p className="h1-61BF95 font-NotoSerif center">
              {content.bloc_stats.bloc_numbers.col2.number}
            </p>
            <h4 className="h4-stats margin-top-20 center">
              {content.bloc_stats.bloc_numbers.col2.text}
            </h4>
          </div>
          <div className="mosaic-bloc resp-stats">
            <p className="h1-61BF95 font-NotoSerif center">
              {content.bloc_stats.bloc_numbers.col3.number}
            </p>
            <h4 className="h4-stats margin-top-20 center">
              {content.bloc_stats.bloc_numbers.col3.text}
            </h4>
          </div>
        </div>

        <h2 className="margin-top-50 margin-bottom-50">
          {content.bloc_valeurs.title}
        </h2>
        <div className="mosaic margin-bottom-50">
          {content.bloc_valeurs.valeurs.map((valeur) => {
            return (
              <div
                key={Math.random()}
                className="mosaic-block resp-center flex-basis-15"
              >
                <img
                  className="resp-width-15 margin-bottom-10"
                  src={valeur.image.url}
                  alt={valeur.image.description}
                />
                <h3>{valeur.title}</h3>
                <p className="resp-center">{valeur.text}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div id="equipe" className="bg-team margin-bottom-50 padding-50-0">
        <div className="content-block flex-column">
          <div className="flex-row flex-space-between">
            <h2 className="resp-margin-top-0 h2-64">
              {content.bloc_team.title}
            </h2>
            <h2 className="resp-margin-top-0 h2-64">
              {imageIndex + 1 + " / " + content.bloc_team.bloc_slider.length}
            </h2>
          </div>
          <Slider {...settings} className="sliderContainer">
            {content.bloc_team.bloc_slider.map((slide, idx) => (
              <div
                key={Math.random()}
                className={idx === imageIndex ? "slide activeSlide" : "slide"}
              >
                <img src={slide.photo.url} alt={slide.photo.description} />
                <div className="textContainer">
                  <h2 className="flex-start text-shadow">
                    {slide.firstname + " " + slide.lastname}
                  </h2>
                  <p className="text-shadow">{slide.poste}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {partners !== undefined ? _partners(content, partners) : ""}

      {garanties !== undefined ? _garanties(content, garanties) : ""}

      <section className="newsletter">
        <div className="content-block flex-column">
          <h3 className="font-size-36">{content.bloc_project2.title}</h3>
          <Link to="/contact">
            <button>{content.bloc_project2.button_text}</button>
          </Link>
        </div>
      </section>

      <Footer />
    </div>
  );
}
