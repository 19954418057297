import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";

import NotFound from "./NotFound";
import Footer from "./Footer";
import BreadCrumb from "./breadCrumb";

export default function Actualites() {
  const [content, setContent] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [categoriesRendered, setCategoriesRendered] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(undefined);

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    async function fetchData() {
      let dataURL =
        "https://admin.groupe-fic.fr//wp-json/wp/v2/posts?_embed&per_page=100";
      fetch(dataURL)
        .then((res) => res.json())
        .then((res) => {
          let result = res;

          if (selectedCategory !== undefined) {
            result = res.filter((res) =>
              res.acf.categories.some(
                (category) => category.name === selectedCategory
              )
            );
          }
          setContent(result);
        });
    }

    async function fetchCategories() {
      if (categoriesRendered === 0) {
        setCategoriesRendered(1);
        let dataURL =
          "https://admin.groupe-fic.fr//wp-json/wp/v2/categories?_embed&per_page=100";
        fetch(dataURL)
          .then((res) => res.json())
          .then((res) => {
            res.forEach((category) => {
              if (
                category.slug !== "a-la-une" &&
                category.slug !== "non-classe" &&
                category.slug !== "uncategorized"
              ) {
                setCategories((categories) => [
                  ...categories,
                  {
                    name: category.name,
                    slug: category.slug,
                    count: category.count,
                  },
                ]);
              }
            });
          });
      }
    }

    fetchData();
    fetchCategories();
  }, [selectedCategory, categoriesRendered]);

  if (content === undefined) {
    return "";
  }

  if (content === null) {
    return <NotFound />;
  }

  if (content.length === 0) {
    return (
      <div>
        <Helmet>
          <title>Actualités - Groupe FIC</title>
          <meta
            name="description"
            content="Retrouvez les dernières actualités du groupe FIC."
          />
          <link rel="canonical" href="https://groupe-fic.fr/actualites" />
        </Helmet>
        <div className="content-block">
          <BreadCrumb page="<strong>Actualités</strong>" />
        </div>

        <div className="content-block flex-column">
          <h1 className="margin-bottom-30">Dernières actualités</h1>
          {/* {categories !== undefined ? _categories(categories) : ""} */}
          <div className="mosaic flex-start margin-bottom-50">
            <button
              onClick={refreshPage}
              key={Math.random()}
              className={
                selectedCategory === undefined
                  ? "buttonCategory menuCategory active"
                  : "buttonCategory menuCategory"
              }
            >
              Tout
            </button>
            {categories.map((category) => {
              if (category.count !== 0) {
                return (
                  <button
                    key={Math.random()}
                    className={
                      selectedCategory === category.name
                        ? "buttonCategory menuCategory active"
                        : "buttonCategory menuCategory"
                    }
                    onClick={() => setSelectedCategory(category.name)}
                  >
                    {category.name}
                  </button>
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className="content-block">
            <p>Aucun résultat.</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Actualités - Groupe FIC</title>
        <meta
          name="description"
          content="Retrouvez les dernières actualités du groupe FIC."
        />
      </Helmet>
      <div className="content-block">
        <BreadCrumb page="<strong>Actualités</strong>" />
      </div>

      <div className="content-block flex-column">
        <h1 className="margin-bottom-30">Dernières actualités</h1>
        {/* {categories !== undefined ? _categories(categories) : ""} */}
        <div className="mosaic flex-start margin-bottom-30">
          <button
            onClick={refreshPage}
            key={Math.random()}
            className={
              selectedCategory === undefined
                ? "buttonCategory menuCategory active"
                : "buttonCategory menuCategory"
            }
          >
            Tout
          </button>
          {categories.map((category) => {
            if (category.count !== 0) {
              return (
                <>
                  <button
                    key={Math.random()}
                    className={
                      selectedCategory === category.name
                        ? "buttonCategory menuCategory active"
                        : "buttonCategory menuCategory"
                    }
                    onClick={() => setSelectedCategory(category.name)}
                  >
                    {category.name}
                  </button>
                </>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      <Link to={`/article/${content[0].slug}`}>
        <div className="block right-61BF95 padding-50-0">
          <div className="content-block">
            <div className="block-50 flex-align-center resp-shadow-61bf95 flex-center">
              <img
                className="img-actu"
                src={content[0].acf.image.url}
                alt={content[0].acf.image.description}
              />
            </div>
            <div className="block-50 padding-40 bg-fff">
              <div className="flex-row flex-space-between align-items-center margin-bottom-10">
                <button className="buttonCategory">
                  {content[0].acf.categories[0].slug !== "a-la-une" &&
                  content[0].acf.categories[0].slug !== "non-classe" &&
                  content[0].acf.categories[0].slug !== "uncategorized"
                    ? content[0].acf.categories[0].name
                    : content[0].acf.categories[1].name}
                </button>
              </div>
              <h2 className="resp-margin-top-0">{content[0].title.rendered}</h2>
              <p className="margin-bottom-5" style={{ fontStyle: "italic" }}>
                <strong>{moment(content[0].date).format("LL")}</strong>
              </p>
              <p>{content[0].acf.accroche}</p>

              <button>En savoir plus</button>
            </div>
          </div>
        </div>
      </Link>

      <section className="padding-10-0">
        <div className="content-block flex-column">
          <div className="flex-row flex-wrap">
            {content.slice(1, content.length).map((article) => {
              return (
                <Link
                  key={Math.random()}
                  className="resp-width-100 flex-2-0-30 text-decoration-none"
                  to={`/article/${article.slug}`}
                >
                  <div className="article flex-column">
                    <button className="buttonCategory">
                      {article.acf.categories[0].slug !== "a-la-une" &&
                      article.acf.categories[0].slug !== "non-classe" &&
                      article.acf.categories[0].slug !== "uncategorized"
                        ? article.acf.categories[0].name
                        : article.acf.categories[1].name}
                    </button>
                    <h3 className="margin-top-15">{article.title.rendered}</h3>
                    <p className="margin-10-0">{article.acf.accroche}</p>
                    <p>
                      <Link
                        className="moreInfos"
                        to={`/article/${article.slug}`}
                      >
                        Lire plus
                      </Link>
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>

      <section className="newsletter2 padding-0">
        <div
          className="content-block padding-0"
          style={{ marginBottom: "-25px" }}
        >
          {window.innerWidth > 1024 ? (
            <iframe
              title="newsletter"
              class="mj-w-res-iframe"
              frameborder="0"
              scrolling="yes"
              marginheight="0"
              marginwidth="0"
              src="https://app.mailjet.com/widget/iframe/7g5g/LL1"
              width="100%"
            ></iframe>
          ) : (
            <iframe
              title="resp-newsletter"
              class="mj-w-res-iframe"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://app.mailjet.com/widget/iframe/7g5g/LQz"
              width="100%"
            ></iframe>
          )}
        </div>
        <script
          type="text/javascript"
          src="https://app.mailjet.com/statics/js/iframeResizer.min.js"
        ></script>
      </section>

      <Footer />
    </div>
  );
}
