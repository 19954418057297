import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Moment from "moment";

import NotFound from "./NotFound";
import Header from "./Header";
import Footer from "./Footer";
import BreadCrumb from "./breadCrumb";

import "../styles/Projects.css";
import "../styles/Articles.css";

export default function Articles(props) {
  const slug = props.match.params.slug;
  const [content, setContent] = useState(undefined);
  const [articles, setArticles] = useState(undefined);
  const [header, setHeader] = useState(undefined);
  const [footer, setFooter] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      let dataURL =
        "https://admin.groupe-fic.fr//wp-json/wp/v2/posts?_embed&per_page=100";
      fetch(dataURL)
        .then((res) => res.json())
        .then((res) => {
          const data = res.filter((content) => content.slug === slug);
          if (data.length > 0) {
            setContent(data[0]);
            setHeader(data[0].yoast_head_json);
          } else {
            window.location.replace("/404");
          }
        });
    }

    function fetchArticles() {
      let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/posts?_embed";
      fetch(dataURL)
        .then((res) => res.json())
        .then((res) => {
          setArticles(res);
        });
    }
    function fetchFooter() {
      let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/footer";
      fetch(dataURL)
        .then((res) => res.json())
        .then((res) => {
          setFooter(res[0].acf);
        });
    }

    fetchFooter();
    fetchData();
    fetchArticles();
  }, [slug]);

  if (content === undefined || articles === undefined || header === undefined) {
    return "";
  }

  if (content === null) {
    return <NotFound />;
  }
  console.log(content);

  return (
    <div className="Impots">
      {header !== undefined ? (
        <Header content={header} match={props.match.url} />
      ) : (
        ""
      )}
      <div className="content-block padding-responsive" style={{transform:"scale(1)"}}>
        <BreadCrumb
          page={`<a href="/actualites">Actualités</a> / <strong>${content.title.rendered}</strong>`}
        />
      </div>
      <div className="content-block padding-responsive" style={{transform:"scale(1)"}}>
      <div className="badge">
              {content.acf.categories[0].slug !== "a-la-une" &&
              content.acf.categories[0].slug !== "non-classe" &&
              content.acf.categories[0].slug !== "uncategorized"
                ? content.acf.categories[0].name
                : content.acf.categories[1].name}
            </div>
            <h1 className="blog-h1">{content.title.rendered}</h1>
            <p>{Moment(content.date).format("LLL")}</p>
      </div>
      <div className="block">
        <div className="content-block padding-responsive" style={{transform:"scale(1)"}}>
          <div className="block-70-left padding-right-32">
            <div className="align-items-center resp-width-100 resp-margin-top-25">
              <img
                style={{ width: "100%" }}
                className="margin-bottom-30"
                src={content.acf.image.url}
                alt={content.acf.image.description}
              />
            </div>
            <div className="text-justify textarticle">
              {ReactHtmlParser(content.acf.block_texts.text1)}
              {ReactHtmlParser(content.acf.block_texts.text2)}
            </div>
            <div className="spererate margin-bottom-50 margin-top-50"></div>
            <h2 className="margin-bottom-30">Articles similaires</h2>
            <div className="articles flex-row resp-flex-column">
              {articles.slice(0, 3).map((article) => {
                if (content.title.rendered !== article.title.rendered) {
                  return (
                    <Link key={Math.random()} to={`/article/${article.slug}`}>
                      <div className="article flex-column">
                        <button className="buttonCategory margin-bottom-15">
                          {article.acf.categories[0].slug !== "a-la-une" &&
                          article.acf.categories[0].slug !== "non-classe" &&
                          article.acf.categories[0].slug !== "uncategorized"
                            ? article.acf.categories[0].name
                            : article.acf.categories[1].name}
                        </button>
                        <h4>{article.title.rendered}</h4>
                        <p className="margin-10-0">{article.acf.accroche}</p>
                        <p>
                          <Link
                            className="moreInfos"
                            to={`/article/${article.slug}`}
                          >
                            Lire plus
                          </Link>
                        </p>
                      </div>
                    </Link>
                  );
                }
                return null;
              })}
            </div>
          </div>
          <div
            className="block-30 padding-left-32 align-items-center sticky blogRightBar sidebar-sticky"
          >
            <div>
              <h2 className="margin-bottom-30 blog-h2">Articles récents</h2>
              <div className="articles resp-flex-column">
                {articles.slice(0, 4).map((article) => {
                  if (content.title.rendered !== article.title.rendered) {
                    return (
                      <Link key={Math.random()} to={`/article/${article.slug}`}>
                        <div
                          className="article flex-column margin-bottom-15"
                          style={{ maxWidth: "350px" }}
                        >
                          <button className="buttonCategory margin-bottom-15">
                            {article.acf.categories[0].slug !== "a-la-une" &&
                            article.acf.categories[0].slug !== "non-classe" &&
                            article.acf.categories[0].slug !== "uncategorized"
                              ? article.acf.categories[0].name
                              : article.acf.categories[1].name}
                          </button>
                          <h4 className="blog-h4">{article.title.rendered}</h4>
                          <p className="margin-10-0">
                            {article.acf.accroche.slice(1, 75)}...
                          </p>
                          <p>
                            <Link
                              className="moreInfos"
                              to={`/article/${article.slug}`}
                            >
                              Lire plus
                            </Link>
                          </p>
                        </div>
                      </Link>
                    );
                  }
                  return null;
                })}
              </div>
              <h2 className="margin-bottom-15 blog-h2">RESTEZ CONNECTÉS !</h2>
              <div
                className="flex-align-center margin-top-20 flex-row"
              >
                {footer.social.map((reseau) => {
                  return (
                    <a
                      key={Math.random()}
                      href={reseau.link}
                      target="_blank"
                      rel="noreferrer"
                      className="width-auto"
                    >
                      <img
                        className="scale-08"
                        src={reseau.image.url}
                        alt={reseau.image.description}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="newsletter2 padding-0">
        <div
          className="content-block padding-0"
          style={{ marginBottom: "-25px" }}
        >
          {window.innerWidth > 1024 ? (
            <iframe
              title="newsletter"
              class="mj-w-res-iframe"
              frameborder="0"
              scrolling="yes"
              marginheight="0"
              marginwidth="0"
              src="https://app.mailjet.com/widget/iframe/7g5g/LL1"
              width="100%"
            ></iframe>
          ) : (
            <iframe
              title="resp-newsletter"
              class="mj-w-res-iframe"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://app.mailjet.com/widget/iframe/7g5g/LQz"
              width="100%"
            ></iframe>
          )}
        </div>
        <script
          type="text/javascript"
          src="https://app.mailjet.com/statics/js/iframeResizer.min.js"
        ></script>
      </section>
      <Footer />
    </div>
  );
}
