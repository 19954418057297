
import Footer from "./Footer";
import BreadCrumb from "./breadCrumb";
import { Link } from "react-router-dom";

export default function NotFound(){
    return(
    <div>
        <div className="content-block">
            <BreadCrumb page={`404`} />
        </div>
        <div className="content-block">
            <div className="block-50 color-61BF95">
                <h1>Oups !</h1>
                <h2>La page que vous recherchez semble introuvable.</h2>
                <Link className="white text-decoration-none" to="/">
                    <button className="nextButton">Accueil</button>
                </Link>
            </div>
            <div className="block-50 flex-center">
                <img src="/images/fic-green.svg" alt="Logo FIC" width="50%" />
            </div>
        </div>

        <Footer />
    </div>
    )
}