import { useState, useEffect } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import ReactHtmlParser from 'react-html-parser';

import "../styles/Footer.css"

function Footer() {
    const [projects, setProjects] = useState(undefined);
    const [solutions, setSolutions] = useState(undefined);
    const [actualites, setActualites] = useState(undefined);
    const [respDisplay, setRespDisplay] = useState(undefined);
    const [footer, setFooter] = useState(undefined);

    let date = new Date();

    useEffect(() => {
        function fetchData(){
            let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/menu";
            fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                const projets = res[0].acf.blocks.filter(block => block.page === "vos-projets");
                const solution = res[0].acf.blocks.filter(content => content.page === "nos-competences");
                setProjects(projets);
                setSolutions(solution);
            })
        }

        function fetchActualites(){
            let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/posts";
            fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                setActualites(res)
            })
        }

        function fetchFooter(){
            let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/footer";
            fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                setFooter(res[0].acf)
            })
        }

        fetchData();
        fetchActualites();
        fetchFooter();
        
    }, []);
    if(projects === undefined || solutions === undefined || actualites === undefined || footer === undefined){
        return "";
    }

    return (
        <footer>
            <div className="content-block padding-bottom-10">
            <div className="grid">
                <div className="grid-bloc-2">    
                    <img className="logo resp-margin-bottom-50 margin-left-20" src={footer.logo.url} alt={footer.logo.description} />
                    <div className="bloc-2">
                        <div>
                            {ReactHtmlParser(footer.adresse_1)}
                        </div>
                        <div className="flex-align-center margin-top-20 flex-row">
                            {footer.social.map(reseau => {
                                return(
                                    <a key={Math.random()} href={reseau.link} target="_blank" rel="noreferrer" className="width-auto"><img className="scale-08" src={reseau.image.url} alt={reseau.image.description} /></a>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div>
                <div className="grid-bloc">
                    <div className="nav-footer">
                        <div className="flex-row width-100 flex-space-between">
                            <p className="white resp-nav-footer" onClick={() => setRespDisplay("projects")}><strong>Vos projets</strong></p>
                            {respDisplay === "projects" ?
                            <img className="display-resp"
                            src="/images/see-less.svg"
                            alt="Dérouler" 
                            onClick={() => setRespDisplay(undefined)} />
                            :
                            <img className="display-resp"
                            src="/images/see-more.svg"
                            alt="Dérouler" 
                            onClick={() => setRespDisplay("projects")} />
                            }     
                        </div>
                        <div className={respDisplay === "projects" ? "flex-column" : "flex-column resp-display-none"}>
                        {projects.map(project => {
                            return(
                            <Link key={Math.random()} to={"/" + project.page + "/" + project.slug}>
                                <p className="white opacity-06">{project.title}</p>
                            </Link>
                            )
                        })}
                    </div>
                    </div>
                </div>

                <div className="grid-bloc">
                    <div className="nav-footer">
                        <div className="flex-row width-100 flex-space-between">
                            <p className="white resp-nav-footer" onClick={() => setRespDisplay("solutions")}><strong>Nos compétences</strong></p>
                            {respDisplay === "solutions" ?
                            <img className="display-resp"
                            src="/images/see-less.svg"
                            alt="Dérouler" 
                            onClick={() => setRespDisplay(undefined)} />
                            :
                            <img className="display-resp"
                            src="/images/see-more.svg"
                            alt="Dérouler" 
                            onClick={() => setRespDisplay("solutions")} />
                            }
                            </div>
                        <div className={respDisplay === "solutions" ? "flex-column" : "flex-column resp-display-none"}>
                            {solutions.map(solution => {
                            return(
                            <Link key={Math.random()} to={"/" + solution.page + "/" + solution.slug}>
                                <p className="white opacity-06">{solution.title}</p>
                            </Link>
                            )
                            })}
                        </div>
                    </div>
                    
                </div>
                <div className="grid-bloc">
                    <div className="nav-footer">
                        <div className="flex-row width-100 flex-space-between">
                            <p className="white" onClick={() => setRespDisplay("actualites")}><strong>Actualités</strong></p>
                            {respDisplay === "actualites" ?
                            <img className="display-resp"
                            src="/images/see-less.svg"
                            alt="Dérouler" 
                            onClick={() => setRespDisplay(undefined)} />
                            :
                            <img className="display-resp"
                            src="/images/see-more.svg"
                            alt="Dérouler" 
                            onClick={() => setRespDisplay("actualites")} />
                            }
                        </div>
                        <div className={respDisplay === "actualites" ? "flex-column" : "flex-column resp-display-none"}>
                            {actualites.slice(0,4).map(article => {
                            return(
                            <Link key={Math.random()} to={"/article/" + article.slug}>
                                <p className="white opacity-06">{article.title.rendered}</p>
                            </Link>
                            );
                            })}
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <hr className="opacity-06 resp-display-none" />
            <ul className="resp-display-none margin-bottom-0">
                <li className="white opacity-06 margin-left-0"><Link to="/informations/mentions-legales"><p className="white margin-top-0">Mentions légales</p></Link></li>
                <li className="white opacity-06"><Link to="/informations/agrements-et-statuts"><p className="white margin-top-0">Agréments et statuts</p></Link></li>
                <li className="white opacity-06"><Link to="/informations/politique-de-confidentialite"><p className="white margin-top-0">Confidentialité</p></Link></li>
                <li className="white opacity-06">
                    <span
                    className="cursor-pointer"
                    onClick={() => {
                        window._axcb = window._axcb || [];
                        window._axcb.push(function(sdk){
                        sdk.openCookies()
                        });
                     }}
                    ><p className="white margin-top-0">Cookies</p></span></li>
                {/* <li className="white opacity-06"><a href="/sitemap.xml"><p className="white margin-top-0">Plan du site</p></a></li> */}
                <li className="white opacity-06 last"><p className="white margin-top-0">© {date.getFullYear()} - Groupe FIC | Fait avec &#9825; par <a href="https://teaps.fr" className="white text-decoration-none" target="_blank" rel="noreferrer">TEAPS</a></p></li>
            </ul>
            </div>
        </footer>

        
    );
}

export default Footer;