import { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

import Header from "./Header";
import Footer from "./Footer";
import BreadCrumb from "./breadCrumb";

import "../styles/Projects.css";

export default function Solutions(props) {
    const slug = props.match.params.slug;
    const [content, setContent] = useState(undefined);
    const [articles, setArticles] = useState(undefined);
    const [title, setTitle] = useState(undefined);
    const [header, setHeader] = useState(undefined);

    useEffect(() => {
        async function fetchData(){
            let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/solutions?_embed&per_page=100";
            fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                const data = res.filter(content => content.slug === slug);
                if(data.length > 0){
                    setContent(data[0].acf.flex_content);
                    setTitle(data[0].title.rendered);
                    setHeader(data[0].yoast_head_json);
                } else {
                    window.location.replace("/404");
                }
            })
        }

        function fetchArticles(){
            let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/posts?_embed&per_page=100";
            fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                setArticles(res) 
            })
        }

        fetchData();
        fetchArticles();
        
    }, [slug]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    if(content === undefined){
        return "";
    }

    return (
        <div className="Impots">
            {header !== undefined ? <Header content={header} match={props.match.url} /> : ""}
            
            <div className="content-block">
                <BreadCrumb page={` Nos compétences / <strong>${title}</strong>`} />
            </div>
            {content.map(flex_content => {
            switch(flex_content.acf_fc_layout){
            case "bloc_100":
            return (
            <div key={Math.random()} className="content-block padding-25-0">
                {flex_content.title !== "" ? <h2 className="margin-bottom-30">{flex_content.title}</h2> : ""}
                {flex_content.text !== "" ? ReactHtmlParser(flex_content.text) : ""}
            </div>
            );
            case "bloc_text_text":
            return(
            <div key={Math.random()} className="block flex-column">
                <div className="content-block">
                    {flex_content.title !== "" ? <h2 className="margin-bottom-30">{flex_content.title}</h2> : ""}
                    {flex_content.text !== "" ? ReactHtmlParser(flex_content.text) : ""}
                </div>
                <div className="content-block padding-right-32">
                    <div className="block-50">
                        {flex_content.text_1 !== "" ? ReactHtmlParser(flex_content.text_1) : ""}
                    </div>
                    <div className="block-50 padding-left-32">
                        {flex_content.text_2 !== "" ? ReactHtmlParser(flex_content.text_2) : ""}
                    </div>
                </div>
            </div>
            );
            case "bloc_image_text":
            return(
            <div className="block flex-column left-1e1e1e padding-25-0">
                <div className="content-block align-items-center">
                    <div className="block-50 flex-align-center">
                        <img src={flex_content.image.url} alt={flex_content.image.description} /> 
                    </div>
                    <div className="block-50 padding-left-32">
                        {flex_content.bloc_right.text !== "" ? ReactHtmlParser(flex_content.bloc_right.text) : ""}
                    </div>
                </div>
            </div>
            );
            case "bloc_text_image":
            return(
            <div className="block right-1e1e1e padding-25-0">
                <div className="content-block align-items-center">
                    <div className="block-50 padding-right-32">
                        {flex_content.bloc_left.text !== "" ? ReactHtmlParser(flex_content.bloc_left.text) : ""}
                    </div>
                    <div className="block-50 flex-end">
                        <img src={flex_content.image.url} alt={flex_content.image.description} /> 
                    </div>
                </div>
            </div>
            )
            case "bloc_articles":
            return(
            <div key={Math.random()} className="content-block">
                {flex_content.title !== "" ? <h2 className="margin-bottom-30">{flex_content.title}</h2> : ""}
                {flex_content.text !== "" ? ReactHtmlParser(flex_content.text) : ""}
                <div className="block block-content">
                    {flex_content.content!== false ?
                        flex_content.content.map(block => {
                            return(
                                <div key={Math.random()} className="mosaic-block">
                                    <h3>{block.title}</h3>
                                    {ReactHtmlParser(block.text)}
                                    {block.link !== "" ? <Link to={block.link}><button>En savoir plus</button></Link> : ""}
                                </div> 
                            );
                        })
                        :
                        ""
                    }
                </div>
            </div>
            )
            case "bloc_contact":
            return(
            <section key={Math.random()} className="newsletter">
                <div className="content-block">
                    {flex_content.title !== "" ? <h3 className="margin-bottom-0"><strong>{flex_content.title}</strong></h3> : ""}
                    {flex_content.link !== "" ? <Link to={flex_content.link}><button>{flex_content.button}</button></Link> : ""}
                </div>
            </section>
            )
            case "bloc_dispositifs":
            return(
            <div key={Math.random()} className="content-block">
                {flex_content.title !== "" ? <h2 className="margin-bottom-30">{flex_content.title}</h2> : ""}
                {flex_content.text !== "" ? ReactHtmlParser(flex_content.text) : ""}
                <div className="block">
                    <div className="mosaic">
                        {flex_content.content !== false ?
                            flex_content.content.map(block => {
                                const url = block.image.url;
                                return(
                                <Link to={block.link}>
                                    <div key={Math.random()} className="mosaic-bloc square-290" style={{backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundImage: `url(${url})`}}>
                                        <h3 className="white center">{block.title}</h3>
                                    </div>
                                </Link>
                                );
                            })
                            :
                            ""
                        }
                    </div>
                </div>
            </div>
            )
            case "bloc_actualite":
            return(
                <div key={Math.random()} className="content-block padding-50-0">
                    {flex_content.title !== "" ? <h2 className="margin-bottom-30">{flex_content.title}</h2> : ""}
                    {flex_content.text !== "" ? ReactHtmlParser(flex_content.text) : ""}
                    <div className="articles flex-row resp-flex-column">
                    {articles !== undefined ?
                    articles.slice(0, 3).map(article => {
                    return(
                    <Link className="text-decoration-none" key={Math.random()} to={`/article/${article.slug}`}>
                        <div className="article flex-column">
                                <button className="buttonCategory">
                                {article.acf.categories[0].slug !== "a-la-une" &&
                                article.acf.categories[0].slug !== "non-classe" &&
                                article.acf.categories[0].slug !== "uncategorized" ?
                                article.acf.categories[0].name
                                :
                                article.acf.categories[1].name}
                                </button>
                                <h4>{article.title.rendered}</h4>
                                <p className="margin-0">{article.acf.accroche}</p>
                                <p><Link className="moreInfos" to={`/article/${article.slug}`}>Lire plus</Link></p>
                        </div>
                    </Link>
                    )}) : ""}
                </div>
            </div>
            )
            case "bloc_list":
            return(
            <div key={Math.random()} className="content-block">
                {flex_content.title !== "" ? <h2 className="margin-bottom-30">{flex_content.title}</h2> : ""}
                {flex_content.text !== "" ? ReactHtmlParser(flex_content.text) : ""}

                <div className="mosaic flex-space-around">
                    {flex_content.content !== false ?
                    flex_content.content.map(block => {
                        return(
                        <div key={Math.random()} className="mosaic-block flex-basis-15">
                            <div>
                                <img className="margin-bottom-30" src={block.image.url} alt={block.image.description} />
                            </div>
                            {block.title !== "" ? <h4>{block.title}</h4> : ""}
                            {ReactHtmlParser(block.text)}
                        </div>
                        )
                    }) 
                    : ""}
                </div>
            </div>
            )
            default:
            return ""
            }
            })}
        <Footer />
    </div>
    );
}