import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

export default function BreadCrumb(params){
    return(
        <div className="breadCrumb">
            <Link to="/">Accueil </Link>
            <span>&nbsp;/ {ReactHtmlParser(params.page)}</span>
        </div>
    );
}
