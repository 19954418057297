
import { Helmet } from "react-helmet";

export default function Header(props){
    const header = props.content;
    const url = props.match;
    return(
    <Helmet>
        <title>{header.title}</title>
        <meta name="description" content={header.description} />
        <meta property="og:title" content={header.og_title} />
        <meta property="og:description" content={header.og_description} />
        {/* <meta property="og:url" content={header.og_url} /> */}
        {header.og_image !== undefined ? <meta property="og:image" content={header.og_image[0].url} /> : ""}
        <link rel="canonical" href={`https://groupe-fic.fr${url}`} />
    </Helmet>
    )
}