import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import useOnClickOutside from "./use-onclick-outside";

import "../styles/Menu.css"

function Menu(props) {
    const ref = useRef();
    const [menu, setMenu] = useState(undefined);
    const [slug, setSlug] = useState("vos-projets");
    const [projectStatus, setProjectStatus] = useState(false);
    const [solutionsStatus, setSolutionsStatus] = useState(false);
    const [dispositifsStatus, setDispositifsStatus] = useState(false);
    const [lightMode, setLightMode] = useState(false);
    const [auditMode, setAuditMode] = useState(false);
    const [respProjects, setRespProjects] = useState(false);
    const [respSolutions, setRespSolutions] = useState(false);
    const [respProduits, setRespProduits] = useState(false);
    let barre = "";
    props.barre === "1e1e1e" ? barre = "dark" : barre = "dark";

    useOnClickOutside(ref, () => setLightMode(false));

    useEffect(() => {
        function fetchData(){
            let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/menu";
            fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                setMenu(res[0].acf) })
        }
        fetchData();
        
    }, [slug]);
    
    if(menu === undefined){
        return "";
    }
    
    const blocks = menu.blocks.filter(block => block.page === slug);

    let gap = "gap-2";
    if(slug === "dispositifs"){
        gap = "gap-2 margin-bottom-10";
    }

    return (
        <div>
            <nav className={lightMode ? `light ${barre}` : `${barre}`} ref={ref}>
                <div className={lightMode ? `menu-block ${barre} light` : `menu-block ${barre}`}>
                    {window.location.pathname === "/" ?
                        <a href="/">

                            {lightMode ?
                            <img className="logo" src="/images/GROUPE-FIC.svg" alt="Groupe FIC" />
                            :
                            <img className="logo" src="/images/GROUPE-FIC-WHITE.svg" alt="Groupe FIC" />                
                            }
                        </a>
                        :
                        <Link 
                        to="/"
                        onClick={() => {
                        setLightMode(false);
                        setSolutionsStatus(false);
                        setProjectStatus(false);
                        }}>
                            {lightMode ?
                            <img className="logo" src="/images/GROUPE-FIC.svg" alt="Groupe FIC" />
                            :
                            <img className="logo" src="/images/GROUPE-FIC-WHITE.svg" alt="Groupe FIC" />                
                            }
                        </Link>
                    }

                    <ul id="largeMenu">
                        <li>
                            <Link
                            to="#" 
                            className={lightMode && projectStatus ? "activeMenu" : ""} 
                            onClick={() => {
                                setSlug("vos-projets");
                                if(solutionsStatus || dispositifsStatus){
                                    setLightMode(true);
                                    setSolutionsStatus(false);
                                    setProjectStatus(true);
                                    setDispositifsStatus(false);
                                } else {
                                    if(projectStatus){
                                        if(lightMode){
                                            setLightMode(false);
                                        } else {
                                            setLightMode(true);
                                        }
                                    } else {
                                        setProjectStatus(true);
                                        if(lightMode){
                                            setLightMode(false);
                                        } else {
                                            setLightMode(true);
                                        }
                                    }
                                }
                            }}
                            >
                                Vos projets
                                {lightMode ?
                                <img className="arrow" src="/images/arrow-up.svg" alt="Fermer" />
                                :
                                <img className="arrow" src="/images/arrow-down.svg" alt="Ouvrir" />
                                }
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to="#" 
                            className={lightMode && solutionsStatus ? "activeMenu" : ""} 
                            onClick={() => {
                                setSlug("nos-competences");
                                setProjectStatus(false);
                                if(projectStatus || dispositifsStatus){
                                    setLightMode(true);
                                    setSolutionsStatus(true);
                                    setProjectStatus(false);
                                    setDispositifsStatus(false);
                                } else {
                                    if(solutionsStatus){
                                        if(lightMode){
                                            setLightMode(false);
                                        } else {
                                            setLightMode(true);
                                        }
                                    } else {
                                        setSolutionsStatus(true);
                                        if(lightMode){
                                            setLightMode(false);
                                        } else {
                                            setLightMode(true);
                                        }
                                    } 
                                }             
                            }}
                            >
                                Nos compétences
                                {lightMode ?
                                <img className="arrow" src="/images/arrow-up.svg" alt="Fermer" />
                                :
                                <img className="arrow" src="/images/arrow-down.svg" alt="Ouvrir" />
                                }
                            </Link>
                        </li>
                        <li>
                            <Link
                            to="#" 
                            className={lightMode && dispositifsStatus ? "activeMenu" : ""} 
                            onClick={() => {
                                setSlug("dispositifs");
                                if(projectStatus || solutionsStatus){
                                    setLightMode(true);
                                    setSolutionsStatus(false);
                                    setDispositifsStatus(true);
                                    setProjectStatus(false);
                                } else {
                                    if(dispositifsStatus){
                                        if(lightMode){
                                            setLightMode(false);
                                        } else {
                                            setLightMode(true);
                                        }
                                    } else {
                                        setDispositifsStatus(true);
                                        if(lightMode){
                                            setLightMode(false);
                                        } else {
                                            setLightMode(true);
                                        }
                                    }
                                }
                            }}
                            >
                                Nos solutions
                                {lightMode ?
                                <img className="arrow" src="/images/arrow-up.svg" alt="Fermer" />
                                :
                                <img className="arrow" src="/images/arrow-down.svg" alt="Ouvrir" />
                                }
                            </Link>
                        </li>
                        <li 
                        onClick={() => {
                        setLightMode(false);
                        setSolutionsStatus(false);
                        setProjectStatus(false);
                        }}>
                            <Link to="/le-groupe">
                                Le groupe
                            </Link>
                        </li>
                        <li 
                        onClick={() => {
                        setLightMode(false);
                        setSolutionsStatus(false);
                        setProjectStatus(false);
                        }}><Link to="/actualites">Actualités</Link></li>
                        <li 
                        onClick={() => {
                        setLightMode(false);
                        setSolutionsStatus(false);
                        setProjectStatus(false);
                        }}><Link to="/contact">Contact</Link></li>
                    </ul>
                    <Link className="resp-display-none" to="/simulation">
                        <button 
                        className="getAnAudit" 
                        // onClick={() => setAuditMode(!auditMode)}
                        >
                            <strong>Obtenir un audit personnalisé</strong>
                        </button>
                    </Link>
                </div>

                {lightMode && (solutionsStatus || projectStatus || dispositifsStatus) ? 
                <div className="your-project">
                    <div className="content-block border-top-61BF95 flex-row width-100">
                        <div>
                            {slug === "vos-projets" ?
                            <div>
                                <h1 className="margin-right-10 h1menu">{menu.title_project}</h1>
                                <p className="margin-right-20">{menu.text_project}</p>
                            </div>
                            :""}
                            {slug === "nos-competences" ?
                            <div>
                                <h1 className="margin-right-30 h1menu">{menu.title_solution}</h1>
                                <p className="margin-right-20">{menu.text_solution}</p>
                            </div>
                            :""}
                            {slug === "dispositifs" ?
                            <div>
                                <h1 className="margin-right-10 h1menu">{menu.title_dispositifs}</h1>
                                <p className="margin-right-20">{menu.text_dispositifs}</p>
                            </div>
                            :""}
                        </div>
                        <div className={`block ${gap}`}>
                            {blocks.map(block => {
                            return(
                            <div 
                            key={Math.random()} 
                            className="mosaic-block"
                            onClick={() => {
                            setLightMode(false);
                            setSolutionsStatus(false);
                            setProjectStatus(false);
                            }}>
                                <Link className="flex-row gap-1-5" to={`/${slug}/${block.slug}`}>
                                {block.number !== "" ? <h2 className="color-61BF95">{block.number}</h2> : ""}
                                    <div>
                                        <h3>{block.title}</h3>
                                        {block.text !== "" ? <p>{block.text}</p> : ""}
                                    </div>
                                </Link>
                            </div>
                            )})}
                        </div>
                    </div>
                    
                </div>
                : ""
                }

                <div id="menuToggle">
                    <input 
                    id="checkboxBurger" 
                    type="checkbox"
                    onClick={() => {
                        setRespProduits(false);
                        setRespSolutions(false);
                        setRespProjects(false);
                    }} />
                    <span className="burger"></span>
                    <span className="burger"></span>
                    <span className="burger"></span>
                
                    <ul className="menu">
                        <li
                        className="cursor-pointer"
                        onClick={() => {
                            setSlug("vos-projets");
                            setRespProjects(!respProjects);
                            setRespSolutions(false);
                            setRespProduits(false);
                        }}>
                            <Link 
                            to="#"
                            onClick={() => {
                                setSlug("vos-projets");
                                setRespProjects(!respProjects);
                                setRespSolutions(false);
                                setRespProduits(false);
                            }}>
                                Vos projets
                            </Link>
                            {respProjects ?                             
                                <img className="arrow" src="/images/arrow-down.svg" alt="aller à" />
                                :
                                <img className="arrow" src="/images/right-arrow.svg" alt="aller à" />
                            }
                        </li>
                        {blocks.map(block => {
                            return(
                            <Link 
                            onClick={() => document.getElementById("checkboxBurger").checked = false}
                            to={`/${slug}/${block.slug}`}
                            >
                                <li key={Math.random()} className={respProjects ? "display-block" : "display-none"}>
                                    <span className="color-61BF95 resp-color-white">{block.number}</span> {block.title}
                                </li> 
                            </Link>
                            )
                        })}
                        <li
                        className="cursor-pointer"
                        onClick={() => {
                            setSlug("nos-competences");
                            setRespSolutions(!respSolutions);
                            setRespProjects(false);
                            setRespProduits(false);
                        }}>
                            <Link 
                            to="#"
                            onClick={() => {
                                setSlug("nos-competences");
                                setRespSolutions(!respSolutions);
                                setRespProjects(false);
                                setRespProduits(false);
                            }}>
                                Nos compétences
                            </Link>
                            {respSolutions ?                             
                                <img className="arrow" src="/images/arrow-down.svg" alt="aller à" />
                                :
                                <img className="arrow" src="/images/right-arrow.svg" alt="aller à" />
                            }
                        </li>
                        {blocks.map(block => {
                            return(
                            <Link 
                            onClick={() => document.getElementById("checkboxBurger").checked = false}
                            to={`/${slug}/${block.slug}`}
                            >
                                <li key={Math.random()} className={respSolutions ? "display-block" : "display-none"}>
                                    <span className="color-61BF95">{block.number}</span> {block.title}
                                </li> 
                            </Link>
                            )
                        })}
                        <li
                        className="cursor-pointer"
                        onClick={() => {
                            setSlug("dispositifs");
                            setRespProduits(!respProduits);
                            setRespSolutions(false);
                            setRespProjects(false);
                        }}>
                            <Link 
                            to="#"
                            onClick={() => {
                                setSlug("dispositifs");
                                setRespProduits(!respProduits);
                                setRespSolutions(false);
                                setRespProjects(false);
                            }}>
                                Nos solutions
                            </Link>
                            {respProduits ?                             
                                <img className="arrow" src="/images/arrow-down.svg" alt="aller à" />
                                :
                                <img className="arrow" src="/images/right-arrow.svg" alt="aller à" />
                            }
                        </li>
                        {blocks.map(block => {
                            return(
                            <Link
                            onClick={() => document.getElementById("checkboxBurger").checked = false}
                            to={`/${slug}/${block.slug}`}
                            >
                                <li key={Math.random()} className={respProduits ? "display-block" : "display-none"}>
                                    <span className="color-61BF95 resp-color-white">{block.number}</span> {block.title}
                                </li> 
                            </Link>
                            )
                        })}
                        <Link to="/le-groupe" onClick={() => document.getElementById("checkboxBurger").checked = false}>
                        <li className="font-size-16">
                            Le groupe
                        </li>
                        </Link>
                        <Link to="/actualites" onClick={() => document.getElementById("checkboxBurger").checked = false}>
                            <li className="font-size-16">
                                Actualités
                            </li>
                        </Link>
                        <Link to="/contact" onClick={() => document.getElementById("checkboxBurger").checked = false}>
                            <li className="font-size-16">
                                Contact
                            </li>
                        </Link>
                        <Link className="simulation" to="/simulation" onClick={() => document.getElementById("checkboxBurger").checked = false}>
                            <li className="bg-61BF95">
                                <button 
                                className="resp-buttonAudit" 
                                onClick={() => {
                                    setAuditMode(!auditMode)
                                    document.getElementById("checkboxBurger").checked = false
                                }}
                                >
                                    Obtenir un audit personnalisé
                                </button>
                            </li>
                        </Link>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default Menu;