/* eslint-disable import/no-anonymous-default-export */
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Autoplay } from "swiper";
import { useState, useEffect } from "react";

import "../styles/Swiper.css";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/autoplay/autoplay.min.css";


export default () => {
  const [partners, setPartners] = useState(undefined);
  
  useEffect(() => {
    function fetchPartners() {
      let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/partenaires";
      fetch(dataURL)
        .then((res) => res.json())
        .then((res) => {
          const partenaires = res.filter(
            (content) => content.slug === "partenaires"
          );
          setPartners(partenaires[0].acf.images);
        });
    }
    fetchPartners();
  }, []);

  if (partners !== undefined) {
    let srcs = [];
    partners.forEach((partner) => {
      srcs.push({ src: partner.image.url, alt: partner.image.description });
    });
  }

    let counter = 0;
    partners?.map(() => (counter++))

return (
    <Swiper  
      modules={[Autoplay]}
      loop={true}
      loopedSlides={counter}
      loopFillGroupWithBlank= {false}
      autoplay={{
        delay: 1100,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,        
      }}
      breakpoints={{
        // when window width is >= 640px
        640: {
          width: 640,
          slidesPerView: 1,
          spaceBetween:1,
        },
        // when window width is >= 768px
        768: {
          width: 768,
          slidesPerView: 4,
          spaceBetween:80,
        },
      }}
    >
      {partners?.map((partner) => (
        <SwiperSlide key={Math.random()}>
          <img
            className="img"
            src={partner.image.url}
            alt={partner.image.description}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
