import { useState } from "react";
import { send } from "emailjs-com";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "../styles/Audit.css";

function sendMail(step1, step2, step3, step4, step5, step6, step7, step8, firstName, lastName, cp, email, phone){
    const toSend = {
        from_name: firstName + ' ' + lastName,
        to_name: "Groupe FIC",
        question1: 'Quelle est votre situation familiale ? ' + step1,
        question2: 'Quel est votre âge ? ' + step2,
        question3: 'Combien d\'enfants avez-vous à charge ? ' + step3,
        question4: 'Quelle est votre activité ? ' + step4,
        question5: 'Quels sont vos revenus nets par mois avant impôt ? ' + step5,
        question6: 'Quelle est votre capacité mensuelle d\'épargne ? ' + step6,
        question7: 'Concernant votre résidence principale, vous êtes : ' + step7,
        question8: 'Quels sont vos principaux objectifs ? ' + step8[0] + ', ' + step8[1],
        nom: 'Nom : ' + firstName + ' ' + lastName,
        mail: 'Email : ' + email,
        tel: 'Téléphone : ' + phone,
        codepostal: 'Code postal : ' + cp,
        reply_to: "contact@groupe-fic.fr"
    }
    send(
        'service_rj3dwio',
        'template_wvsaxha',
        toSend,
        'user_ARN1mBlL1uKfbh0LRi7g4'
    )
    .then((response) => {
    console.log('SUCCESS!', response.status, response.text);
    })
    .catch((err) => {
    console.log('FAILED...', err);
    });
}

function _renderSteps(){
    const [step, setStep] = useState(1);
    const [step1, setStep1] = useState(undefined);
    const [step2, setStep2] = useState(undefined);
    const [step3, setStep3] = useState(undefined);
    const [step4, setStep4] = useState(undefined);
    const [step5, setStep5] = useState(undefined);
    const [step6, setStep6] = useState(undefined);
    const [step7, setStep7] = useState(undefined);
    const [step8, setStep8] = useState([]);
    const [firstName, setFirstName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [cp, setCP] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [phone, setPhone] = useState(undefined);
    const [selectedButtonStep1, setSelectedButtonStep1] = useState(undefined);
    const [selectedButtonStep4, setSelectedButtonStep4] = useState(undefined);
    const [selectedButtonStep6, setSelectedButtonStep6] = useState(undefined);
    const [selectedButtonStep7, setSelectedButtonStep7] = useState(undefined);
    const [selectedButtonStep8, setSelectedButtonStep8] = useState([]);
    const [error, setError] = useState(false);


    let borderError = "";
    let borderBottomError = "";
    if (error){
        borderError = "border-error";
        borderBottomError = "border-bottom-error";
    }

    switch(step){            
        case 2:
            return(
                <div className="auditBlock">
                    <p className="center pStep large">QUESTION 2/9</p>
                    
                    <div className="progressBar margin-top-10">
                        <div className={`progression p${step}`} />
                    </div>
                    <h2>Quel est votre âge ?</h2>
                    <input required id="step2" type="number" placeholder="Âge" value={step2 === undefined ? "" : step2} onChange={event => setStep2(event.target.value)} className={borderBottomError} />
                    {error ? <p className="error margin-top-50">Champ obligatoire</p> : ""}
                    <div className="contentButtons flex-row"> 
                        <div className="contentButtons flex-row margin-top-50"> 
                            <button className="previousButton" onClick={() => {
                                setStep(step - 1);
                                setError(false);
                            }}>  
                                <img src="/images/audit_left.svg" alt="Précédent" />
                                Précédent
                            </button>
                            {step2 !== undefined && step2 !== "" ?
                            <button className="nextButton" 
                            onClick={() => {
                                setStep(step + 1);
                                setError(false);
                            }}>
                                Suivant
                                <img src="/images/audit_right.svg" alt="Suivant" />
                            </button>
                            :
                            <button className="nextButton disabled" onClick={() => setError(true)}>
                                Suivant
                                <img src="/images/audit_right.svg" alt="Suivant" />
                            </button>
                            }
                        </div>
                    </div>
                </div>
            );
        case 3:
            return(
                <div className="auditBlock">
                    <p className="center pStep large">QUESTION 3/9</p>
                    <div className="progressBar margin-top-10">
                        <div className={`progression p${step}`} />
                    </div>
                    <h2>Combien d'enfants avez-vous à charge ?</h2>
                    <input id="step3" type="number" placeholder="Nombre d'enfants" value={step3 === undefined ? "" : step3} onChange={event => setStep3(event.target.value)} className={borderBottomError} />
                    {error ? <p className="error margin-top-50">Champ obligatoire</p> : ""}
                    <div className="contentButtons flex-row margin-top-50"> 
                        <button className="previousButton" onClick={() => {
                            setStep(step - 1);
                            setError(false);
                        }}>  
                            <img src="/images/audit_left.svg" alt="Précédent" />
                            Précédent
                        </button>
                        {step3 !== undefined && step3 !== "" ?
                            <button className="nextButton" 
                            onClick={() => {
                                setStep(step + 1);
                                setError(false);
                            }}>
                                Suivant
                                <img src="/images/audit_right.svg" alt="Suivant" />
                            </button>
                            :
                            <button className="nextButton disabled" onClick={() => setError(true)}>
                                Suivant
                                <img src="/images/audit_right.svg" alt="Suivant" />
                            </button>
                            }
                    </div>
                </div>
            );
        case 4:
            return(
                <div className="auditBlock">
                    <p className="center pStep large">QUESTION 4/9</p>
                    <div className="progressBar margin-top-10">
                        <div className={`progression p${step}`} />
                    </div>
                    <h2>Quelle est votre activité ?</h2>
                    <div className="contentButtons">   
                        <button 
                        className={selectedButtonStep4 === 1 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep4("Salarié");
                        setSelectedButtonStep4(1);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Salarié</button>
                        <button 
                        className={selectedButtonStep4 === 2 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep4("Indépendant");
                        setSelectedButtonStep4(2);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Indépendant</button>
                        <button 
                        className={selectedButtonStep4 === 3 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep4("Dirigeant");
                        setSelectedButtonStep4(3);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Dirigeant</button>
                        <button 
                        className={selectedButtonStep4 === 4 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep4("Sans activité");
                        setSelectedButtonStep4(4);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Sans activité</button>
                    </div>
                    {error ? <p className="error margin-top-50">Champ obligatoire</p> : ""}
                    <div className="contentButtons flex-row margin-top-50"> 
                        <button className="previousButton" onClick={() => {
                            setStep(step - 1);
                            setError(false);
                        }}>  
                            <img src="/images/audit_left.svg" alt="Précédent" />
                            Précédent
                        </button>
                        {step4 !== undefined ?
                        <button className="nextButton" 
                        onClick={() => {
                            setStep(step + 1);
                            setError(false);
                        }}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                        :
                        <button className="nextButton disabled" onClick={() => setError(true)}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                        }
                    </div>
                </div>
            );
        case 5:
            return(
                <div className="auditBlock">
                    <p className="center pStep large">QUESTION 5/9</p>
                    <div className="progressBar margin-top-10">
                        <div className={`progression p${step}`} />
                    </div>
                    <h2>Quels sont vos revenus <span className="underline">nets par mois</span> avant impôt ?</h2>
                    <input type="text" placeholder="2500 euros" value={step5 === undefined ? "" : step5} onChange={event => setStep5(event.target.value)} className={borderBottomError} />
                    {error ? <p className="error margin-top-50">Champ obligatoire</p> : ""}
                    <div className="contentButtons flex-row margin-top-50"> 
                        <button className="previousButton" onClick={() => {
                            setStep(step - 1);
                            setError(false);
                        }}>  
                            <img src="/images/audit_left.svg" alt="Précédent" />
                            Précédent
                        </button>
                        {step5 !== undefined && step5 !== "" ?
                        <button className="nextButton" onClick={() => {
                            setStep(step + 1);
                            setError(false);
                        }}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                        :
                        <button className="nextButton disabled" onClick={() => setError(true)}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                        }
                    </div>
                </div>
            );
        case 6:
            return(
                <div className="auditBlock">
                    <p className="center pStep large">QUESTION 6/9</p>
                    <div className="progressBar margin-top-10">
                        <div className={`progression p${step}`} />
                    </div>
                    <h2>Quelle est votre capacité mensuelle d'épargne ?</h2>
                    <div className="contentButtons">   
                        <button 
                        className={selectedButtonStep6 === 1 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep6("Moins de 200 €");
                        setSelectedButtonStep6(1);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Moins de 200 €</button>
                        <button 
                        className={selectedButtonStep6 === 2 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep6("200-400 €");
                        setSelectedButtonStep6(2);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400);
                        }}>200-400 €</button>
                        <button 
                        className={selectedButtonStep6 === 3 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep6("400-600 €");
                        setSelectedButtonStep6(3);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400);
                        }}>400-600 €</button>
                        <button 
                        className={selectedButtonStep6 === 4 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep6("Plus de 600€");
                        setSelectedButtonStep6(4);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400);
                        }}>Plus de 600€</button>
                    </div>
                    {error ? <p className="error margin-top-50">Champ obligatoire</p> : ""}
                    <div className="contentButtons flex-row margin-top-50"> 
                        <button className="previousButton" onClick={() => {
                            setStep(step - 1);
                            setError(false);
                        }}>   
                            <img src="/images/audit_left.svg" alt="Précédent" />
                            Précédent
                        </button>
                        {step6 !== undefined ?
                        <button className="nextButton" onClick={() => setStep(step + 1)}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                        :
                        <button className="nextButton disabled" onClick={() => setError(true)}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                        }
                    </div>
                </div>
            );
        case 7:
            return(
                <div className="auditBlock">
                    <p className="center pStep large">QUESTION 7/9</p>
                    <div className="progressBar margin-top-10">
                        <div className={`progression p${step}`} />
                    </div>
                    <h2>Concernant votre résidence principale, vous êtes :</h2>
                    <div className="contentButtons">   
                        <button 
                        className={selectedButtonStep7 === 1 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep7("Propriétaire (avec emprunt en cours)");
                        setSelectedButtonStep7(1);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Propriétaire (avec emprunt en cours)</button>
                        <button 
                        className={selectedButtonStep7 === 2 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep7("Propriétaire (sans emprunt)");
                        setSelectedButtonStep7(2);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Propriétaire (sans emprunt)</button>
                        <button 
                        className={selectedButtonStep7 === 3 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep7("Locataire");
                        setSelectedButtonStep7(3);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Locataire</button>
                        <button 
                        className={selectedButtonStep7 === 4 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep7("Hébergé à titre gratuit");
                        setSelectedButtonStep7(4);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Hébergé à titre gratuit</button>
                    </div>
                    {error ? <p className="error margin-top-50">Champ obligatoire</p> : ""}
                    <div className="contentButtons flex-row margin-top-50"> 
                        <button className="previousButton" onClick={() => {
                            setStep(step - 1);
                            setError(false);
                        }}> 
                            <img src="/images/audit_left.svg" alt="Précédent" />
                            Précédent
                        </button>
                        {step7 !== undefined ?
                        <button className="nextButton" onClick={() => setStep(step + 1)}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                        :
                        <button className="nextButton disabled" onClick={() => setError(true)}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                        }
                    </div>
                </div>
            );
        case 8:
            let sb1 = selectedButtonStep8.filter(sb => sb.value === 1);
            let sb2 = selectedButtonStep8.filter(sb => sb.value === 2);
            let sb3 = selectedButtonStep8.filter(sb => sb.value === 3);
            let sb4 = selectedButtonStep8.filter(sb => sb.value === 4);
            let sb5 = selectedButtonStep8.filter(sb => sb.value === 5);
            let sb6 = selectedButtonStep8.filter(sb => sb.value === 6);
            return(
                <div className="auditBlock">
                    <p className="center pStep large">QUESTION 8/9</p>
                    <div className="progressBar margin-top-10">
                        <div className={`progression p${step}`} />
                    </div>
                    <h2>Quels sont vos principaux objectifs ? (2 choix max)</h2>
                    <div className="contentButtons">   
                        <button 
                        className={sb1.length > 0 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        if(sb1.length > 0){
                            const newSB = selectedButtonStep8.filter(SB => SB.value !== 1);
                            setSelectedButtonStep8(newSB);

                            const newStep8 = step8.filter(S8 => S8 !== "Réduire mes impôts");
                            setStep8(newStep8);
                            setError(false);
                        } else {
                            setSelectedButtonStep8(selectedButtonStep8 => [...selectedButtonStep8, {value: 1}]);
                            setStep8(step8 => [...step8, "Réduire mes impôts"]);
                            setError(false);
                        }
                        }}>Réduire mes impôts</button>

                        <button 
                        className={sb2.length > 0 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        if(sb2.length > 0){
                            const newSB = selectedButtonStep8.filter(SB => SB.value !== 2);
                            setSelectedButtonStep8(newSB);

                            const newStep8 = step8.filter(S8 => S8 !== "Développer mon patrimoine");
                            setStep8(newStep8);
                            setError(false);
                        } else {
                            setSelectedButtonStep8(selectedButtonStep8 => [...selectedButtonStep8, {value: 2}]);
                            setStep8(step8 => [...step8, "Développer mon patrimoine"]);
                            setError(false);
                        }
                        }}>Développer mon patrimoine</button>

                        <button 
                        className={sb3.length > 0 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        if(sb3.length > 0){
                            const newSB = selectedButtonStep8.filter(SB => SB.value !== 3);
                            setSelectedButtonStep8(newSB);

                            const newStep8 = step8.filter(S8 => S8 !== "Préparer ma retraite");
                            setStep8(newStep8);
                            setError(false);
                        } else {
                            setSelectedButtonStep8(selectedButtonStep8 => [...selectedButtonStep8, {value: 3}]);
                            setStep8(step8 => [...step8, "Préparer ma retraite"]);
                            setError(false);
                        }
                        }}>Préparer ma retraite</button>

                        <button 
                        className={sb4.length > 0 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        if(sb4.length > 0){
                            const newSB = selectedButtonStep8.filter(SB => SB.value !== 4);
                            setSelectedButtonStep8(newSB);

                            const newStep8 = step8.filter(S8 => S8 !== "Transmettre mon patrimoine");
                            setStep8(newStep8);
                            setError(false);
                        } else {
                            setSelectedButtonStep8(selectedButtonStep8 => [...selectedButtonStep8, {value: 4}]);
                            setStep8(step8 => [...step8, "Transmettre mon patrimoine"]);
                            setError(false);
                        }
                        }}>Transmettre mon patrimoine</button>

                        <button 
                        className={sb5.length > 0 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        if(sb5.length > 0){
                            const newSB = selectedButtonStep8.filter(SB => SB.value !== 5);
                            setSelectedButtonStep8(newSB);

                            const newStep8 = step8.filter(S8 => S8 !== "Compléter ses revenus");
                            setStep8(newStep8);
                            setError(false);
                        } else {
                            setSelectedButtonStep8(selectedButtonStep8 => [...selectedButtonStep8, {value: 5}]);
                            setStep8(step8 => [...step8, "Compléter ses revenus"]);
                            setError(false);
                        }
                        }}>Compléter ses revenus</button>

                        <button 
                        className={sb6.length > 0 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        if(sb6.length > 0){
                            const newSB = selectedButtonStep8.filter(SB => SB.value !== 6);
                            setSelectedButtonStep8(newSB);

                            const newStep8 = step8.filter(S8 => S8 !== "Investir dans l'immobilier");
                            setStep8(newStep8);
                            setError(false);
                        } else {
                            setSelectedButtonStep8(selectedButtonStep8 => [...selectedButtonStep8, {value: 6}]);
                            setStep8(step8 => [...step8, "Investir dans l'immobilier"]);
                            setError(false);
                        }
                        }}>Investir dans l'immobilier</button>
                    </div>
                    {error ? <p className="error margin-top-50">Champ obligatoire</p> : ""}
                    <div className="contentButtons flex-row margin-top-50"> 
                        <button className="previousButton" onClick={() => {
                            setStep(step - 1);
                            setError(false);
                        }}> 
                            <img src="/images/audit_left.svg" alt="Précédent" />
                            Précédent
                        </button>
                        {step8.length !== 0 && step8.length < 3 ?
                            <button className="nextButton" onClick={() => setStep(step + 1)}>
                                Suivant
                                <img src="/images/audit_right.svg" alt="Suivant" />
                            </button>
                            :
                            <button className="nextButton disabled" onClick={() => setError(true)}>
                                Suivant
                                <img src="/images/audit_right.svg" alt="Suivant" />
                            </button>
                            }
                    </div>
                </div>
            );
        case 9:
            return(
                <div className="auditBlock">
                    <p className="center pStep large">QUESTION 9/9</p>
                    <div className="progressBar margin-top-10">
                        <div className={`progression p${step}`} />
                    </div>
                    <h2>Quelles sont vos coordonnées ?</h2>
                    <div className="col-3">
                        <input className={`font-size-20 ${borderBottomError}`} type="text" placeholder="Nom" value={lastName === undefined ? "" : lastName} onChange={event => setLastName(event.target.value)} />
                        <input className={`font-size-20 ${borderBottomError}`} type="text" placeholder="Prénom" value={firstName === undefined ? "" : firstName} onChange={event => setFirstName(event.target.value)} />
                        <input className={`font-size-20 ${borderBottomError}`} type="text" placeholder="Code postal" value={cp === undefined ? "" : cp} onChange={event => setCP(event.target.value)} />
                    </div>
                    <div className="flex-row flex-space-between margin-top-30">
                        <input className={`font-size-20 col-2 ${borderBottomError}`} type="email" placeholder="votremail@email.fr" value={email === undefined ? "" : email} onChange={event => setEmail(event.target.value)} />
                        <input className={`font-size-20 col-2 ${borderBottomError}`} type="text" placeholder="Téléphone" value={phone === undefined ? "" : phone} onChange={event => setPhone(event.target.value)} />
                    </div>
                    {error ? <p className="error margin-top-50">Champ obligatoire</p> : ""}
                    <div className="contentButtons flex-row margin-top-50"> 
                        <button className="previousButton" onClick={() => {
                            setStep(step - 1);
                            setError(false);
                        }}>   
                            <img src="/images/audit_left.svg" alt="Précédent" />
                            Précédent
                        </button>
                        {firstName !== undefined && firstName !== ""
                        && lastName !== undefined && lastName !== ""
                        && cp !== undefined && cp !== ""
                        && phone !== undefined && phone !== ""
                        && email !== undefined && email !== "" ?
                        <button className="nextButton" onClick={() => {
                            setStep(step + 1)
                            sendMail(step1, step2, step3, step4, step5, step6, step7, step8, firstName, lastName, cp, email, phone)
                        }}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                        :
                        
                        <button className="nextButton disabled" onClick={() => setError(true)}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                        }
                    </div>
                </div>
            );
        case 10:
            return(
                <div className="auditBlock">
                    <div className="progressBar margin-top-10">
                        <div className={`progression p${step}`} />
                    </div>
                    <h2>Merci d’avoir répondu à notre questionnaire !</h2>
                    <p>Nous vous contacterons dans les plus brefs délais.</p>
                    <div className="contentButtons flex-row margin-top-50"> 
                        <Link className="white text-decoration-none" to="/">
                            <button className="nextButton">Terminer</button>
                        </Link>
                    </div>
                </div>
            )
        default:
            return(
                <div className="auditBlock">
                    <div className="progressBar margin-top-10">
                        <div className={`progression p${step}`} />
                    </div>
                    <p className="center pStep large">QUESTION 1/9</p>
                    <h2>Quelle est votre situation familiale ?</h2>
                    <div className="contentButtons">   
                        <button 
                        className={selectedButtonStep1 === 1 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep1("Célibataire");
                        setError(false);
                        setSelectedButtonStep1(1);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Célibataire</button>

                        <button 
                        className={selectedButtonStep1 === 2 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep1("Marié ou Pacsé");
                        setSelectedButtonStep1(2);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Marié ou Pacsé</button>

                        <button 
                        className={selectedButtonStep1 === 3 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep1("Divorcé");
                        setSelectedButtonStep1(3);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Divorcé</button>

                        <button 
                        className={selectedButtonStep1 === 4 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep1("En concubinage");
                        setSelectedButtonStep1(4);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>En concubinage</button>

                        <button 
                        className={selectedButtonStep1 === 5 ? `button1 active ${borderError}` : `button1 ${borderError}`}
                        onClick={() => {
                        setStep1("Veuf");
                        setSelectedButtonStep1(5);
                        setError(false);
                        setTimeout(function(){
                            setStep(step + 1)
                        }, 400); 
                        }}>Veuf</button>
                    </div>
                    {error ? <p className="error margin-top-50">Champ obligatoire</p> : ""}
                    {step1 !== undefined ?
                    <div className="contentButtons flex-row margin-top-50"> 
                        <button className="nextButton" onClick={() => setStep(step + 1)}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                    </div>
                    :
                    <div className="contentButtons flex-row margin-top-50"> 
                        <button className="nextButton disabled" onClick={() => setError(true)}>
                            Suivant
                            <img src="/images/audit_right.svg" alt="Suivant" />
                        </button>
                    </div>
                    }
                </div>
            );
    }
}

export default function Audit() {
    return (
    <div>
        <Helmet>
            <title>Groupe FIC - Audit</title>
            <meta name="description" content="Cet audit personnalisé nous permet de trouver la solution adaptée à votre projet en seulement une minute." />
            <link rel="canonical" href="https://groupe-fic.fr/simulation" />
        </Helmet>
        <div className="content-block">
            <div className={`Audit activeAudit`}>
            <h1 className="font-size-24 center">Simulation personnalisée</h1>
                <div className="topAuditQuestionnaire">
                </div>
                <div className="contentAudit">
                    {_renderSteps()}
                </div>
            </div>
        </div>
    </div>
    );
}