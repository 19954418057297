import { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';

import NotFound from "./NotFound";
import Header from "./Header";
import Footer from "./Footer";
import BreadCrumb from "./breadCrumb";

export default function Informations(props){
    const slug = props.match.params.slug;
    const [content, setContent] = useState(undefined);
    const [header, setHeader] = useState(undefined);

    useEffect(() => {
        function fetchData(){
            let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/pages?slug=" + slug;
            fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                setContent(res[0].acf)
                setHeader(res[0].yoast_head_json)
            })
        }

        fetchData();
    }, [slug]);

    if(content === undefined){
        return "";
    }

    if(content === null){
        return <NotFound />
    }

    return(
    <div>
        <div className="content-block">
            <BreadCrumb page={`<strong>${content.title}</strong>`} />
        </div>

        <div className="content-block flex-column margin-bottom-100">
            <h1>{content.title}</h1>
            {ReactHtmlParser(content.content)}
        </div>

        {header !== undefined ? <Header content={header} match={props.match.url} /> : ""}

        <Footer />
    </div>
    )
}