import { useState, useEffect, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import "../styles/Swiper.css";
import "../styles/Home.css";

const NotFound = lazy(() => import("./NotFound"));
const Header = lazy(() => import("./Header"));
const Footer = lazy(() => import("./Footer"));
const Carousel = lazy(() => import("./Carousel"));
const renderLoader = () => <p>Loading</p>;

// Fonction de défilement horizontal
// el est l'élément HTML qui contiendra le contenu défilant
// src est un tableau comprenant le chemin des images à faire défiler
// pas est le pas d'incrémentation du défilement (défaut 1px)
// tps est le temps entre deux incrémentations  (défaut 50ms)
function defilImgHrz(el, srcs, pas1, tps1) {
  if (typeof el == "string") {
    el = document.getElementById(el);
  }
  if (el !== null) {
    var tps = tps1;
    var pas = pas1;
    var imgs = [];
    var offset = -150;
    for (var i = 0, l = srcs.length; i < l; i++) {
      var img = new Image();
      img.src = srcs[i].src;
      img.alt = srcs[i].alt;
      imgs.push(img);
      img.style.height = "70%";
      img.style.position = "absolute";
      img.style.left = offset + "px";
      el.appendChild(img);
      offset += img.offsetWidth;
    }
    var first = 0;
    var last = imgs.length - 1;

    (function d() {
      for (var i = 0, l = imgs.length; i < l; i++) {
        var left = parseInt(imgs[i].style.left, 10);
        imgs[i].style.left = left - pas + "px";
        if (i === first && left - pas + imgs[i].offsetWidth < 0) {
          imgs[i].style.left =
            parseInt(imgs[last].style.left, 10) +
            imgs[last].offsetWidth -
            (i === 0 ? pas : 0) +
            "px";
          last = first++;
          if (first > imgs.length - 1) {
            first = 0;
          }
        }
      }
      setTimeout(d, tps);
    })();
  }
}

function _articles(content, articles) {
  return (
    <section className="blog">
      <div className="content-block">
        <h2 className="center margin-bottom-20">{content.blog_title}</h2>
        <div className="articles margin-bottom-20">
          <Link to={`/article/${articles[0].slug}`}>
            <div className="article">
              <div>
                <button className="buttonCategory">
                  {articles[0].acf.categories[0].slug !== "a-la-une" &&
                  articles[0].acf.categories[0].slug !== "non-classe" &&
                  articles[0].acf.categories[0].slug !== "uncategorized"
                    ? articles[0].acf.categories[0].name
                    : articles[0].acf.categories[1].name}
                </button>
                <h4>{articles[0].title.rendered}</h4>
                <p>{articles[0].acf.accroche}</p>
                <p>
                  <Link
                    className="moreInfos"
                    to={`/article/${articles[0].slug}`}
                  >
                    Lire plus
                  </Link>
                </p>
              </div>
            </div>
          </Link>
          {articles.slice(1, 3).map((article) => {
            return (
              <Link key={Math.random()} to={`/article/${article.slug}`}>
                <div className="article flex-column">
                  <button className="buttonCategory">
                    {article.acf.categories[0].slug !== "a-la-une" &&
                    article.acf.categories[0].slug !== "non-classe" &&
                    article.acf.categories[0].slug !== "uncategorized"
                      ? article.acf.categories[0].name
                      : article.acf.categories[1].name}
                  </button>
                  <h4>{article.title.rendered}</h4>
                  <p>{article.acf.accroche}</p>
                  <p>
                    <Link className="moreInfos" to={`/article/${article.slug}`}>
                      Lire plus
                    </Link>
                  </p>
                </div>
              </Link>
            );
          })}
        </div>

        <button className="buttonMore margin-top-10">
          <Link to="/actualites">Voir plus d'articles</Link>
        </button>
      </div>
    </section>
  );
}

function _garanties(content, garanties) {
  return (
    <section className="guarantees">
      <div className="content-block">
        <h2 className="center">{content.guarantees_title}</h2>
        <div>
          {garanties.map((garantie) => {
            return (
              <img
                key={Math.random()}
                src={garantie.image.url}
                alt={garantie.image.description}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default function Home(props) {
  const [content, setContent] = useState(undefined);
  const [partners, setPartners] = useState(undefined);
  const [articles, setArticles] = useState(undefined);
  const [garanties, setGaranties] = useState(undefined);
  const [projectSlider, setProjectSlider] = useState("moveRight");
  const [groupSlider, setGroupSlider] = useState(0);
  const [menuCount, setMenuCount] = useState(0);
  const [sousMenuCount, setSousMenuCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("0-0");
  const [header, setHeader] = useState(undefined);
  // const [solutionDisplay, setSolutionDisplay] = useState(true);

  const style = {
    transform: `translateX(${-100 * groupSlider}%)`,
  };

  useEffect(() => {
    function fetchData() {
      let dataURL =
        "https://admin.groupe-fic.fr//wp-json/wp/v2/pages?slug=accueil";
      fetch(dataURL)
        .then((res) => res.json())
        .then((res) => {
          setContent(res[0].acf);
          setHeader(res[0].yoast_head_json);
        });
    }

    function fetchPartners() {
      let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/partenaires";
      fetch(dataURL)
        .then((res) => res.json())
        .then((res) => {
          const partenaires = res.filter(
            (content) => content.slug === "partenaires"
          );
          const garanties = res.filter(
            (content) => content.slug === "garanties"
          );
          setPartners(partenaires[0].acf.images);
          setGaranties(garanties[0].acf.images);
        });
    }

    function fetchArticles() {
      let dataURL = "https://admin.groupe-fic.fr//wp-json/wp/v2/posts";
      fetch(dataURL)
        .then((res) => res.json())
        .then((res) => {
          setArticles(res);
        });
    }

    fetchData();
    fetchPartners();
    fetchArticles();
  }, []);

  if (content === undefined) {
    return "";
  }

  if (content === null) {
    return <Suspense fallback={<renderLoader />}><NotFound /> </Suspense>;
  }

  if (partners !== undefined) {
    let srcs = [];
    partners.forEach((partner) => {
      srcs.push({ src: partner.image.url, alt: partner.image.description });
    });
    setTimeout(defilImgHrz("bandeau_horizontal", srcs, 1, 10), 3000);
  }

  return (
    <div>
      <Suspense fallback={<renderLoader />}>
        {header !== undefined ? (
          <Header content={header} match={props.match.url} />
        ) : (
          ""
        )}
      </Suspense>

      <section className="getAudit">
        <div className="content-block blockAudit">
          <div className="left-block-35">
            {ReactHtmlParser(content.audit_left.audit_title)}
            <p className="white mw440">{content.audit_left.audit_text}</p>
            <Link className="simulation" to="/simulation">
              <button className="getAnAudit">
                Obtenir un audit personnalisé
              </button>
            </Link>
          </div>
          <div className="right-block right-block-65">
            <img
              src={content.audit_image.url}
              alt={content.audit_image.description}
            />
          </div>
        </div>
      </section>

      {/* {partners !== undefined ? _partners(partners) : ""} */}
      <Suspense fallback={renderLoader()}>
        <section className="partners">
          <div className="content-block">
            <h3 className="margin-bottom-0">Nos partenaires</h3>
            <div className="bandeau_horizontal" style={{}}>
              <Carousel />
            </div>
          </div>
        </section>
      </Suspense>

      <section id="presentation" className="presentation">
        <div className="content-block">
          <div className="sliderContainer">
            <div className="sliderPresentation" style={style}>
              {content.presentation_images.map((image) => {
                return (
                  <img
                    key={Math.random()}
                    src={image.image.url}
                    alt={image.image.description}
                  />
                );
              })}
            </div>
            <div className="presentationButtons">
              <button
                className="goLeft"
                onClick={() =>
                  groupSlider <= 2 && groupSlider > 0
                    ? setGroupSlider(groupSlider - 1)
                    : ""
                }
              >
                <img src="./images/left.svg" alt="Précédent" />
              </button>
              <button
                className="goRight"
                onClick={() =>
                  groupSlider >= 0 && groupSlider < 2
                    ? setGroupSlider(groupSlider + 1)
                    : ""
                }
              >
                <img src="./images/right.svg" alt="Suivant" />
              </button>
            </div>
          </div>
          <div>
            <h2>{content.presentation_right.presentation_title}</h2>
            {ReactHtmlParser(content.presentation_right.presentation_text)}
            <button className="margin-top-20">
              <Link to="/le-groupe">
                {content.presentation_right.presentation_button}
              </Link>
            </button>
          </div>
        </div>
      </section>

      <section className="project">
        <div className="content-block flex-column">
          <div className="topProject padding-25-0 resp-padding-0">
            <h2 className="resp-margin-0">{content.project_title}</h2>
            <div className="projectButtons">
              <button
                className="goLeft"
                onClick={() => setProjectSlider("moveRight")}
              >
                <img src="./images/left.svg" alt="Précédent" />
              </button>
              <button
                className="goRight"
                onClick={() => setProjectSlider("moveLeft")}
              >
                <img src="./images/right.svg" alt="Suivant" />
              </button>
            </div>
          </div>

          <div className={`mosaic mosaic-project ${projectSlider}`}>
            {content.project_blocks.map((block) => {
              return (
                <div
                  key={Math.random()}
                  className="mosaic-block mosaic-block-project"
                >
                  <div>
                    <Link to={block.project_group.link}>
                      <h4 className="color-61BF95 resp-margin-0">
                        {block.project_group.titre}
                      </h4>
                      <p>{block.project_group.texte}</p>
                      <Link className="moreInfos" to={block.project_group.link}>
                        En savoir plus
                      </Link>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="solution">
        <div className="content-block">
          <div className="image-block">
            <img
              src={content.solution_image.url}
              alt={content.solution_image.description}
              width="100%"
            />
          </div>
          <div>
            <h2>{content.solution_content.solution_title}</h2>
            <p>{content.solution_content.solution_text}</p>
            <Link className="simulation" to="/simulation">
              <button>{content.solution_content.solution_text_button}</button>
            </Link>
          </div>
        </div>
      </section>

      <section className="whyUs">
        <div className="content-block">
          <h2 className="center">{content.whyUs_title}</h2>
          <p className="center margin-bottom-80">{content.whyUs_text}</p>

          <div className="mosaic">
            <div className="mosaic-block margin-bottom-10">
              <img
                className="margin-bottom-10"
                src={content.whyUs_bloc1.whyUs_bloc1_image.url}
                alt={content.whyUs_bloc1.whyUs_bloc1_image.description}
              />
              <h4 className="margin-bottom-10">
                {content.whyUs_bloc1.whyUs_bloc1_title}
              </h4>
              <p>{content.whyUs_bloc1.whyUs_bloc1_text}</p>
            </div>
            <div className="mosaic-block margin-bottom-10">
              <img
                className="margin-bottom-10"
                src={content.whyUs_bloc2.whyUs_bloc2_image.url}
                alt={content.whyUs_bloc2.whyUs_bloc2_image.description}
              />
              <h4 className="margin-bottom-10">
                {content.whyUs_bloc2.whyUs_bloc2_title}
              </h4>
              <p>{content.whyUs_bloc2.whyUs_bloc2_text}</p>
            </div>
            <div className="mosaic-block margin-bottom-10">
              <img
                className="margin-bottom-10"
                src={content.whyUs_bloc3.image.url}
                alt={content.whyUs_bloc3.image.description}
              />
              <h4 className="margin-bottom-10">
                {content.whyUs_bloc3.whyUs_bloc3_title}
              </h4>
              <p>{content.whyUs_bloc3.whyUs_bloc3_text}</p>
            </div>
            <div className="mosaic-block">
              <img
                className="margin-bottom-10"
                src={content.whyUs_bloc4.whyUs_bloc4_image.url}
                alt={content.whyUs_bloc4.whyUs_bloc4_image.description}
              />
              <h4 className="margin-bottom-10">
                {content.whyUs_bloc4.whyUs_bloc4_title}
              </h4>
              <p>{content.whyUs_bloc4.whyUs_bloc4_text}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="nos-solutions">
        <div className="content-block">
          <h2 className="">{content.solutions_title}</h2>

          <div className="solutions">
            <div>
              {content.solutions_content.map((contenu, i = 0) => {
                return (
                  <ul key={Math.random()} className="accordion">
                    <li
                      className={
                        menuCount === i ? "has-subs active" : "has-subs"
                      }
                    >
                      <label
                        className="cursor-pointer"
                        htmlFor={`menu${i}`}
                        onClick={() => {
                          setMenuCount(i);
                          setSousMenuCount(0);
                        }}
                      >
                        {contenu.title}
                      </label>
                      <input
                        id={`menu${i}`}
                        type="checkbox"
                        name="menu"
                        checked={menuCount === i ? true : false}
                        onChange={() => {}}
                      />
                      <ul className="sub margin-bottom-0">
                        {contenu.sous_menus.map((sous_menu, j = 0) => {
                          return (
                            <li
                              key={Math.random()}
                              className={
                                sousMenuCount === j
                                  ? "cursor-pointer active"
                                  : "cursor-pointer"
                              }
                              onClick={() => setSousMenuCount(j)}
                            >
                              {sous_menu.title}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                );
              })}

              <select
                value={selectedOption}
                onChange={(event) => {
                  const values = event.target.value.split("-");
                  setSelectedOption(event.target.value);
                  setMenuCount(parseInt(values[0]));
                  setSousMenuCount(parseInt(values[1]));
                }}
              >
                {content.solutions_content.map((contenu, i = 0) => {
                  return contenu.sous_menus.map((sous_menu, j = 0) => {
                    return (
                      <option key={Math.random()} value={`${i}-${j}`}>
                        {sous_menu.title}
                      </option>
                    );
                  });
                })}
              </select>
            </div>

            <div className="flex-row">
              {content.solutions_content[menuCount].sous_menus[sousMenuCount]
                .image === false ? (
                ""
              ) : (
                <img
                  src={
                    content.solutions_content[menuCount].sous_menus[
                      sousMenuCount
                    ].image.url
                  }
                  alt={
                    content.solutions_content[menuCount].sous_menus[
                      sousMenuCount
                    ].image.description
                  }
                />
              )}
              <div className="flex-column solutions-content">
                <h3>
                  {
                    content.solutions_content[menuCount].sous_menus[
                      sousMenuCount
                    ].title
                  }
                </h3>
                {ReactHtmlParser(
                  content.solutions_content[menuCount].sous_menus[sousMenuCount]
                    .text
                )}
                {content.solutions_content[menuCount].sous_menus[sousMenuCount]
                  .link !== "" ? (
                  <button className="margin-top-20">
                    <Link
                      to={
                        content.solutions_content[menuCount].sous_menus[
                          sousMenuCount
                        ].link
                      }
                    >
                      En savoir plus
                    </Link>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="youAre">
        <div className="content-block">
          <div className="block-50">
            <div className="mosaic resp-display-none ">
              <div className="mosaic-block mosaic-block-youAre bg-61BF95">
                <img
                  src={content.youAre_left.image1.url}
                  alt="Logo Groupe FIC"
                />
              </div>
              <div className="mosaic-block mosaic-block-youAre bg-61BF95">
                <img src={content.youAre_left.image2.url} alt="Entreprise" />
              </div>
              <div className="mosaic-block mosaic-block-youAre bg-61BF95">
                <img src={content.youAre_left.image3.url} alt="Indépendant" />
              </div>
              <div className="mosaic-block mosaic-block-youAre bg-61BF95">
                <img src={content.youAre_left.image4.url} alt="Particulier" />
              </div>
            </div>
            <div className="sliderContainer resp-display-block">
              <div className="sliderPresentation" style={style}>
                <div className="mosaic gap-0">
                  <div className="mosaic-block mosaic-block-youAre fill">
                    <img
                      src={content.youAre_left.image1.url}
                      alt="Logo Groupe FIC"
                    />
                  </div>
                  <div className="mosaic-block mosaic-block-youAre fill bg-61BF95">
                    <img
                      src={content.youAre_left.image2.url}
                      alt="Entreprise"
                    />
                  </div>
                  <div className="mosaic-block mosaic-block-youAre fill">
                    <img
                      src={content.youAre_left.image3.url}
                      alt="Indépendant"
                    />
                  </div>
                  <div className="mosaic-block mosaic-block-youAre fill">
                    <img
                      src={content.youAre_left.image4.url}
                      alt="Particulier"
                    />
                  </div>
                </div>
                <div className="image-block">
                  <img
                    className="mosaic-block-youAre"
                    src={content.youAre_left.image2.url}
                    alt="Entreprise"
                  />
                </div>
                <img src={content.youAre_left.image3.url} alt="Indépendant" />
                <img src={content.youAre_left.image4.url} alt="Particulier" />
              </div>
              <div className="presentationButtons">
                <button
                  className="goLeft"
                  onClick={() =>
                    groupSlider <= 3 && groupSlider > 0
                      ? setGroupSlider(groupSlider - 1)
                      : ""
                  }
                >
                  <img src="./images/left.svg" alt="Précédent" />
                </button>
                <button
                  className="goRight"
                  onClick={() =>
                    groupSlider >= 0 && groupSlider < 3
                      ? setGroupSlider(groupSlider + 1)
                      : ""
                  }
                >
                  <img src="./images/right.svg" alt="Suivant" />
                </button>
              </div>
            </div>
          </div>
          <div className="block-50 right-block-youAre">
            <h2>{content.youAre_right.youAre_title}</h2>
            <p>{content.youAre_right.youAre_text1}</p>
            <p>{content.youAre_right.youAre_text2}</p>
          </div>
        </div>
      </section>

      {articles !== undefined ? _articles(content, articles) : ""}

      {garanties !== undefined ? _garanties(content, garanties) : ""}

      <section className="newsletter2 padding-0">
        <div
          className="content-block padding-0"
          style={{ marginBottom: "-25px" }}
        >
          {window.innerWidth > 1024 ? (
            <iframe
              title="newsletter"
              class="mj-w-res-iframe"
              frameborder="0"
              scrolling="yes"
              marginheight="0"
              marginwidth="0"
              src="https://app.mailjet.com/widget/iframe/7g5g/LL1"
              width="100%"
            ></iframe>
          ) : (
            <iframe
              title="resp-newsletter"
              class="mj-w-res-iframe"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://app.mailjet.com/widget/iframe/7g5g/LQz"
              width="100%"
            ></iframe>
          )}
        </div>
        <script
          type="text/javascript"
          src="https://app.mailjet.com/statics/js/iframeResizer.min.js"
        ></script>
      </section>
      <Suspense fallback={<renderLoader />}>
        <Footer />
      </Suspense>
    </div>
  );
}
