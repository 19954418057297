import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Home from "./components/Home";
import Group from "./components/Group";
import Actualites from "./components/Actualites";
import Projects from "./components/Projects";
import Solutions from "./components/Solutions";
import Articles from "./components/Articles";
import Contact from "./components/Contact";
import Menu from "./components/Menu";
import Dispositifs from "./components/Dispositifs";
import Audit from "./components/Audit"
import NotFound from "./components/NotFound";
import Informations from "./components/Informations";

import './App.css';

function App() {
  return (
    <Router>
      <Menu />
      <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/le-groupe" component={Group} />
      <Route exact path="/vos-projets/:slug" component={Projects} />
      <Redirect exact from="/vos-projets" to="/" />
      <Route exact path="/nos-competences/:slug" component={Solutions} />
      <Redirect exact from="/nos-competences/" to="/" />
      <Route exact path="/actualites" component={Actualites} />
      <Route exact path="/article/:slug" component={Articles} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/dispositifs/:slug" component={Dispositifs} />
      <Route exact path="/dispositifs/:slug/:slug2" component={Dispositifs} />
      <Redirect exact from="/dispositifs" to="/" />
      <Route exact path="/informations/:slug" component={Informations} />
      <Route exact path="/simulation" component={Audit} />
      <Route exact path="/404" component={NotFound} status={404} />
      <Route component={NotFound} status={404} />
      </Switch>
    </Router>
  );
}

export default App;